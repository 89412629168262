import React from "react"
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Container } from "../theme/helpers"
import { Footer } from "../components/footer"
import { Header } from "../components/header"
import { colors, mediaQuery } from "../theme"
import { Link } from "gatsby"
import Seo from "gatsby-plugin-wpgraphql-seo"

const PostContainer = styled.div`
  font-family: Manrope;

  .gatsby-image-wrapper {
    height: 400px;
    width: 100%;
    border-radius: 8px;
  }
`

const Title = styled.h1`
  font-size: 60px;
  font-weight: 700;
  line-height: 69px;
  color: #39B54A;
  position: relative;
  margin: 32px 0;

  ${mediaQuery("lg")} {
    font-size: 36px;
    line-height: 30px;
  }
  ${mediaQuery("sm")} {
    font-size: 26px;
    line-height: 30px;
  }
`
const Content = styled.div`

  h2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
    color: black;
    margin: 32px 0 60px;
  }
  strong {
    font-weight: 700;
  }
  ul {
    list-style: circle;
    list-style-position: inside;

    li {
      margin-bottom: 16px;
      font-size: 20px;
      font-weight: 400;
      line-height: 35px;
      color: black;
    }
  }

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
    margin-bottom: 24px;
    color: black;
  }

  ${mediaQuery("lg")} {
    h2 {
      font-size: 32px;
      line-height: 36px;
    }
    ul {
      li {
        font-size: 14px;
        line-height: 30px;
      }
    }
    p {
      font-size: 14px;
      line-height: 30px;
    }
  }
`

const BlogPost = ({ data }) => {
  const {
    wpPost: { title, content, featuredImage },
    wpPost,
    allWpPost: { totalCount: newsCount },
    allWpEvent: { totalCount: eventsCount },
  } = data

  return (
    <>
      <Header />
      <Seo post={wpPost} />
      <Container>
        <PostContainer>
          {featuredImage && <GatsbyImage
            image={getImage(featuredImage.node.localFile)}
          />
          }
          <Title>{title}</Title>
          <Content dangerouslySetInnerHTML={{ __html: content }}></Content>
        </PostContainer>
      </Container>
      <Footer />
    </>
  )
}

export const query = graphql`
  query($id: String, $lng: String, $locale: String) {
    allWpPost(filter: { locale: { locale: { eq: $locale } } }) {
      totalCount
    }
    allWpEvent(filter: { locale: { locale: { eq: $locale } } }) {
      totalCount
    }
    wpPost(id: { eq: $id }) {
      id
      title
      content
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 2048, quality: 90)
            }
          }
        }
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $lng } }) {
      edges {
        node {
          ...TranslationFragment
        }
      }
    }
  }
`
export default BlogPost
